import React from "react";
import { Header, Footer } from "../../layout";
import {
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaGlobe,
  FaFacebook,
} from "react-icons/fa";
import { CardContent, Card, Container, Grid, Typography } from "@mui/material";
const OrganizationInfo = ({ data }) => {
  const {
    imageUrl,
    name,
    socialMediaLinks,
    description,
    slogan,
    new: is_new,
  } = data;
  return (
    <>
      <Header />
      <>
        <Container maxWidth="md">
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Card className="shadow-sm px-8">
                <CardContent>
                  <img
                    src={imageUrl}
                    alt="Organization Logo"
                    style={{
                      width: "100%",
                      maxHeight: "300px",
                      resizeMode: "contain",
                    }}
                  />
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h5" sx={{ mb: 2 }}>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "4px",
                  }}
                >
                  {name}
                  {is_new && (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        padding: "2px 4px",
                        backgroundColor: "#e80370",
                        color: "white",
                        fontSize: "14px",
                        width: "fit-content",
                        borderRadius: "999px",
                      }}
                    >
                      New
                    </div>
                  )}
                </div>
              </Typography>
              <Typography variant="body1">{slogan}</Typography>
            </Grid>
            <Grid item xs={12} sx={{ mb: 5 }}>
              <Typography variant="body2" sx={{}}>
                {description}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  gap: "40px",
                }}
              >
                {socialMediaLinks.facebook && (
                  <a
                    href={socialMediaLinks.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaFacebook style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.twitter && (
                  <a
                    href={socialMediaLinks.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaTwitter style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.instagram && (
                  <a
                    href={socialMediaLinks.instagram}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaInstagram style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.linkedin && (
                  <a
                    href={socialMediaLinks.linkedin}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaLinkedin style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.youtube && (
                  <a
                    href={socialMediaLinks.youtube}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaYoutube style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
                {socialMediaLinks.other && (
                  <a
                    href={socialMediaLinks.other}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <FaGlobe style={{ height: "24px", width: "24px" }} />
                  </a>
                )}
              </div>{" "}
            </Grid>
          </Grid>
        </Container>
      </>

      <Footer />
    </>
  );
};

export default OrganizationInfo;
{
  /*  */
}
