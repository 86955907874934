import React from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useNavigate,
  Navigate,
} from "react-router-dom";
import "./App.scss";
import { Forgotpwd, Login, Signup } from "./views/auth";
import Landing from "./views/landing";
import User_DashBoard from "./views/components/User_DashBoard/User_DashBoard";
import User_DashBoard_Header from "./views/components/User_DashBoard_Header/User_DashBoard_Header";
import "bootstrap/dist/css/bootstrap.min.css";
import { Toaster } from "react-hot-toast";
import LineChart from "./views/Admin_DashBoard/Line_Chart_data";
import MyAnalyticsPage from "./views/Admin_DashBoard/MyAnalyticsPage";
import SendEmail from "./views/Admin_DashBoard/SendEmail";
import OTP from "./views/auth/OTP";
import About_Us from "./views/components/About_Us/About_Us";
import Contact_Us from "./views/components/Contact_Us/Contact_Us";
import Deep_Dive from "./views/components/Deep_Dive/Deep_Dive";
import Legal_Docs from "./views/components/Legal_Doc/Legal_Docs";
import Settings from "./views/components/Settings/Settings";
import UserDashboard from "./views/userDashboard/userdashboard";
import ReactGA from "react-ga";
import AllDays from "./views/components/Archive/AllDays";
import Archive from "./views/components/Archive/Archive";
import Share from "./views/components/Share/Share";
import { useLocation } from "react-router-dom";
import { Suspense } from "react";
import { shallowEqual, useSelector, useDispatch } from "react-redux";
import { setPreviousRoute } from "./redux/Slices/UserAuth";
import { useEffect } from "react";
import Blogs from "./views/components/Blogs/Blogs";
import BlogDetails from "./views/components/Blogs/BlogDetails";
import PartnerDetail from "./views/components/partners/PartnerDetails";
import UsersDetail from "./views/Admin_DashBoard/users/UsersDetail";

import NotFound from "./views/notfound/NotFound";
import Loader from "./components/shared/loader/ComponentLoader";

const Partners = React.lazy(() =>
  import("./views/components/partners/Partners")
);

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const { isAuth } = useSelector((state) => state?.UserAuth, shallowEqual);

  const currentPath = location.pathname;

  useEffect(() => {
    if (!isAuth) {
      dispatch(setPreviousRoute(currentPath));
    }
  }, [dispatch, isAuth, navigate, currentPath]);
  ReactGA.initialize("UA-286054427-1");

  return (
    <div className="App">
      <Toaster />
      <Suspense fallback={<Loader />}>
        <Routes>
          <Route index element={<Landing />} />
          <Route path="/share/*" element={<Share />} />
          <Route path="OTP" element={<OTP />} />
          <Route path="signup" element={<Signup />} />
          <Route path="login" element={<Login />} />
          <Route path="resetpwd" element={<Forgotpwd />} />
          <Route path="LineChart" element={<LineChart />} />
          <Route path="About_Us" element={<About_Us />} />
          <Route path="Partners" element={<Partners />} />
          <Route path="/partner/:id" element={<PartnerDetail />} />
          <Route path="Contact_Us" element={<Contact_Us />} />
          <Route path="Settings" element={<Settings />} />
          <Route path="Deep_Dive" element={<Deep_Dive />} />
          <Route path="Legal_Docs" element={<Legal_Docs />} />
          <Route path="MyAnalyticsPage" element={<MyAnalyticsPage />} />
          <Route
            path="User"
            element={
              isAuth ? <User_DashBoard_Header /> : <Navigate to="/login" />
            }
          >
            <Route path="DashBoard" element={<User_DashBoard />} />
            <Route path="About_Us" element={<About_Us />} />
            <Route path="Archive" element={<Archive />} />
            <Route path="Contact_Us" element={<Contact_Us />} />
            <Route path="Settings" element={<Settings />} />
            <Route path="sendEmail" element={<SendEmail />} />
            <Route path="details" element={<UsersDetail />} />
          </Route>
          <Route
            path="dashboard"
            element={isAuth ? <UserDashboard /> : <Navigate to="/login" />}
          />
          <Route path="Archive" element={<Archive />} />
          <Route path="AllDays/:yearmonth" element={<AllDays />} />
          <Route path="blogs" element={<Blogs />} />
          <Route path="blogs/:id" element={<BlogDetails />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Suspense>
    </div>
  );
}

export default App;
