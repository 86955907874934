import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import axios from "axios";
import PartnerInfo from "./PartnerInfo";
import { apiUrl } from "../../../config";
import Loader from "../../../components/shared/loader/ComponentLoader";

const PartnerDetail = () => {
  const { id } = useParams();
  const [partner, setPartner] = useState(null);

  useEffect(() => {
    const fetchPartner = async () => {
      const response = await axios.get(`${apiUrl}/api/v1/partners/${id}`);
      setPartner(response?.data);
    };

    fetchPartner();
  }, [id]);

  if (!partner) {
    return (
      <div>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <PartnerInfo data={partner} />
    </div>
  );
};

export default PartnerDetail;
