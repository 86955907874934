import { React, useEffect } from "react";
import { Form, Input, Checkbox, Button } from "antd";
import ReCAPTCHA from "react-google-recaptcha";
import { GoogleLogin } from "@react-oauth/google";
import { Link, useNavigate } from "react-router-dom";
import { useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import {
  User_Data,
  setPreviousRoute,
  updateAuth,
} from "../../redux/Slices/UserAuth";
import { useDispatch, useSelector } from "react-redux";
import { GoogleLoginButton } from "./GoogleLoginButton";
import { Divider } from "@mui/material";
import { apiUrl } from "../../config";

const SignupForm = ({
  onFinish,
  isLoading,
  onRecaptchaChange,
  onFinishFailed,
  isRecaptchaVerified,
}) => {
  // Validator function for the password
  const validatePassword = (_, value) => {
    const passwordCriteriaRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

    // Check if the password is empty
    if (!value) {
        return Promise.reject(new Error("Please input your password!"));
    }

    // Validate the password against the regex
    if (passwordCriteriaRegex.test(value)) {
        return Promise.resolve();
    }

    // Reject if the password doesn't meet criteria
    return Promise.reject(
        new Error(
            "Password must be 8-20 characters long and include at least one letter, one number, and one special character."
        )
    );
};

  const { isAuth, previousRoute } = useSelector((state) => state.UserAuth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const response = await axios.post(
        `${apiUrl}/api/v1/auth/google`,
        { code: codeResponse.code }
      );

      const user = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${response?.data?.data?.access_token}`,
          },
        }
      );

      if (user) {
        const userInfo = {
          email: user?.data?.email,
          UserName: user?.data?.name,
        };
        dispatch(User_Data({ ...userInfo }));
        dispatch(updateAuth({ isAuth: true }));
      }
    },
    flow: "auth-code",
  });

  useEffect(() => {
    if (isAuth) {
      if (previousRoute !== null) {
        navigate(previousRoute);
        dispatch(setPreviousRoute(null));
      } else {
        navigate("/User/DashBoard");
      }
    }
  }, [isAuth, navigate, previousRoute, dispatch]);
  return (
    <Form
      name="basic"
      initialValues={{
        remember: false,
      }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <GoogleLoginButton onClick={loginWithGoogle} />
      <Divider
        sx={{
          "&.MuiDivider-root": {
            "&::before": {
              borderTop: `thin solid #fff`,
            },
            "&::after": {
              borderTop: `thin solid #fff`,
            },
          },
        }}
        style={{
          color: "white",
          marginBottom: "12px",
        }}
        variant="middle"
      >
        Or
      </Divider>
      <Form.Item
        name="UserName"
        rules={[
          {
            required: true,
            message: "Please input your username!",
          },
          { min: 4, max: 20, message: "Username must be between 4 to 20 characters." },
 
        ]}
      >
        <Input
          placeholder="Username"
          className="auth-input signup-input"
          onChange={(e) => {
            window.gtag("event", "input_username", {
              event_category: "Sign Up Form",
              event_label: `Username Entered: ${e.target.value}`,
            });
          }}
        />
      </Form.Item>

      <Form.Item
        name="email"
        rules={[
          {
            required: true,
            message: "Please input your Email!",
          },
          {
            type: "email",
            message: "The input is not valid E-mail!",
          },
        ]}
      >
        <Input
          placeholder="Email Address"
          className="auth-input signup-input "
          onChange={(e) => {
            window.gtag("event", "input_email", {
              event_category: "Sign Up Form",
              event_label: `Email Entered: ${e.target.value}`,
            });
          }}
        />
      </Form.Item>

      <Form.Item
        name="password"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
          {
            validator: validatePassword,
          },
        ]}
      >
        <Input.Password
          placeholder="Password"
          className="signup_password auth-input signup-input"
          onChange={() => {
            window.gtag("event", "input_password", {
              event_category: "Sign Up Form",
              event_label: "Password Entered",
            });
          }}
        />
      </Form.Item>

      <Form.Item
        name="Cpassword"
        rules={[
          {
            required: true,
            message: "Please input your password!",
          },
          ({ getFieldValue }) => ({
            validator(_, value) {
              if (!value || getFieldValue("password") === value) {
                return Promise.resolve();
              }
              return Promise.reject(
                new Error("The two passwords that you entered do not match!")
              );
            },
          }),
        ]}
      >
        <Input.Password
          placeholder="Confirm Password"
          className="signup_password auth-input signup-input"
          type="password"
          onChange={() => {
            window.gtag("event", "input_confirm_password", {
              event_category: "Sign Up Form",
              event_label: "Confirm Password Entered",
            });
          }}
        />
      </Form.Item>
      <Form.Item name="Subscribe" className="signup-agree mt-2">
        <Checkbox
          onChange={(e) => {
            window.gtag("event", "sub_to_newsletter_checkbox", {
              event_category: "Sign Up Form",
              event_label: `Sub to Newsletter Checkbox: ${e.target.checked}`,
            });
          }}
        >
          <span className="auth-text mt-1">Subscribe to Newsletter</span>
        </Checkbox>
      </Form.Item>
      <Form.Item
        name="remember"
        className="signup-agree"
        valuePropName="checked"
        rules={[
          {
            required: true,
            message: "Please accept and agree to the Terms & Conditions!",
          },
          {
            validator(_, value) {
              if (value) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  new Error(
                    "Please accept and agree to the Terms & Conditions!"
                  )
                );
              }
            },
          },
        ]}
      >
        <Checkbox
          onChange={(e) => {
            const isChecked = e.target.checked;
            window.gtag("event", "i_agree_tc_checkbox", {
              event_category: "Sign Up Form",
              event_label: `I Agree to TC Checkbox: ${isChecked}`,
            });
          }}
        >
          <span className="auth-text">
            I agree to the
            <Link
              to="/Legal_Docs?type=2"
              style={{ textDecoration: "none" }}
              onClick={() => {
                window.gtag("event", "tc_btn_click_signup", {
                  event_category: "Sign Up Form",
                  event_label: "TC Button Clicked Signup Form",
                });
              }}
            >
              <span className="auth-text-black ms-2">terms and conditions</span>
            </Link>
          </span>
        </Checkbox>
      </Form.Item>

      <ReCAPTCHA
        sitekey="6LcAQXspAAAAAOcfNFy7QUxODsQQcskKio9m98rd" // Replace with your actual site key
        onChange={onRecaptchaChange}
      />
      <p className="auth-text mt-4 text-center">
        Already have an account?
        <Link
          className="auth-text-black ms-2"
          to="/login"
          style={{ textDecorationLine: "none" }}
        >
          Log in
        </Link>
      </p>
      <Form.Item className="items-center">
        <Button
          className="auth-button signup-button"
          disabled={!isRecaptchaVerified}
          // onClick={() => setSignupStep(1)}
          onClick={() => {
            window.gtag("event", "signup_btn_clicked_signup_form", {
              event_category: "Sign Up Form",
              event_label: "Signup Button Clicked Signup Form",
            });
          }}
          htmlType="submit"
        >
          {isLoading ? (
            <>
              <div class="spinner-border" role="status">
                <span class="visually-hidden">Loading...</span>
              </div>
            </>
          ) : (
            "Sign Up"
          )}
        </Button>
      </Form.Item>
    </Form>
  );
};

export default SignupForm;
