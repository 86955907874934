import React, { useState, useEffect } from "react";
import { Button } from "reactstrap";
import { Divider } from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import { Form, Input } from "antd";
import axios from "axios";
import { toast } from "react-hot-toast";
import {
  User_Data,
  setPreviousRoute,
  updateAuth,
} from "../../redux/Slices/UserAuth";
import { useDispatch, useSelector } from "react-redux";
import { apiUrl } from "../../config";
import Mark from "./Mark";
import { Footer } from "../layout";

import { GoogleLoginButton } from "./GoogleLoginButton";

import { useGoogleLogin } from "@react-oauth/google";

function Login() {
  const { isAuth, previousRoute } = useSelector((state) => state.UserAuth);
  const navigate = useNavigate();
  const [Spinner, setSpinner] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    if (isAuth) {
      if (previousRoute !== null) {
        navigate(previousRoute);
        dispatch(setPreviousRoute(null));
      } else {
        navigate("/User/DashBoard");
      }
    }
  }, [isAuth, navigate, previousRoute, dispatch]);

  const onFinish = async (values) => {
    try {
      setSpinner(true);
      let res = await axios.post(apiUrl + "/api/v1/User_Sign_in", {
        email: values.UserName,
        password: values.password,
      });
      if (res.data.success === true) {
        dispatch(User_Data(res.data.data));
        toast.success(res.data.msg);
        dispatch(updateAuth({ isAuth: true }));
      } else {
        toast.error(res.data.msg);
      }
    } catch (error) {
      toast.error("An error occurred. Please try again later.");
    } finally {
      setSpinner(false);
    }
  };

  const loginWithGoogle = useGoogleLogin({
    onSuccess: async (codeResponse) => {
      const response = await axios.post(
        `${apiUrl}/api/v1/auth/google`,
        { code: codeResponse.code }
      );

      const user = await axios.get(
        "https://www.googleapis.com/oauth2/v3/userinfo",
        {
          headers: {
            Authorization: `Bearer ${response?.data?.data?.access_token}`, // Include access token in Authorization header
          },
        }
      );

      if (user) {
        const userInfo = {
          email: user?.data?.email,
          UserName: user?.data?.name,
        };
        // UserName: "Mohsin Hassan";
        // email: "mohsinbwp2018@gmail.com";
        // loginTime: 19;
        // otp: "139738";
        // password: "$2b$10$j5D9JMqlp7nEEyMZc9vhT.HJg4I1dTHRVVDV/xkoVnTfdIz8jw3me";
        // __v: 0;
        // _id: "65bbd5dc32d5d8ef29a1a619";
        dispatch(User_Data({ ...userInfo }));
        dispatch(updateAuth({ isAuth: true }));
      }
    },
    flow: "auth-code",
  });

  const onFinishFailed = (errorInfo) => {
    toast.error("An error occurred. Please try again later.");
    console.error("Failed:", errorInfo);
  };

  return (
    <>
      <div className="auth">
        <div className="login">
          <div className="login-mark-div">
            <Mark />
          </div>
          <div className="login-info-div">
            <div className="login-info-div-x-space"></div>
            <div className="login-info-main-div">
              <div className="login-info-main-top-space"></div>
              <span className="login-title auth-title">
                <strong>Log In</strong>
              </span>
              <div className="login-item-space-half" />
              <span className="login-text auth-text">Welcome Back!</span>

              <GoogleLoginButton onClick={loginWithGoogle} />

              <Divider
                sx={{
                  "&.MuiDivider-root": {
                    "&::before": {
                      borderTop: `thin solid #fff`,
                    },
                    "&::after": {
                      borderTop: `thin solid #fff`,
                    },
                  },
                }}
                style={{
                  color: "white",
                  marginBottom: "12px"
                }}
                variant="middle"
              >
                Or
              </Divider>

              <Form
                name="basic"
                initialValues={{
                  remember: true,
                }}
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                autoComplete="off"
              >
                <Form.Item
                  name="UserName"
                  rules={[
                    {
                      required: true,
                      message: "Please input your username!",
                    },
                  ]}
                >
                  <Input
                    placeholder="Username Or Email"
                    className="auth-input login-input"
                  />
                </Form.Item>

                <Form.Item
                  name="password"
                  rules={[
                    {
                      required: true,
                      message: "Please input your password!",
                    },
                  ]}
                >
                  <Input.Password
                    placeholder="Password"
                    className="signup_password auth-input login-input mt-1"
                    type="password"
                  />
                </Form.Item>
                <div className="login-item-space-half" />
                <div className="login-agree">
                  <span className="auth-text" style={{ fontSize: "15px" }}>
                    *By Logging in you agree to the
                    <Link to="/Legal_Docs?type=2" style={{ textDecoration: "none" }}>
                      <span
                        className="auth-text-black"
                        style={{ fontSize: "15px" }}
                      >
                        &nbsp;Terms and Conditions
                      </span>
                    </Link>
                  </span>
                </div>
                <div className="login-item-space-half" />
                <Form.Item>
                  <Button
                    className="auth-button login-button"
                    htmlType="submit"
                  >
                    {Spinner ? (
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    ) : (
                      "Login"
                    )}
                  </Button>
                </Form.Item>
              </Form>
              <div className="login-item-space-half" />
              <div className="login-account-question auth-text">
                Need an account?
                <Link
                  className="auth-text-black ms-2"
                  to="/signup"
                  style={{ fontSize: "16px", textDecoration: "none" }}
                >
                  Sign Up{" "}
                </Link>
              </div>
              <div className="login-item-space-half" />
              <Link
                className="login-account-question auth-text-black"
                to="/resetpwd"
                style={{ fontSize: "18px", textDecoration: "none" }}
              >
                Forgot Password?{" "}
              </Link>
              <div className="login-info-main-bottom-space"></div>
            </div>
            <div className="login-info-div-x-space"></div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Login;
