import axios from "axios";
import { apiUrl } from './../../../../config';
export const createPermissions = async (permission) => {
  return await axios.post("/permissions", permission);
};

export const getAllPermissions = async () => {
  return await axios.get("/permissions?limit=50");
};

export const updateRole = async (roleId, body) => {
  return await axios.put(`/roles/${roleId}`, body);
};

export const deleteRole = async (roleId) => {
  return await axios.delete(`/roles/${roleId}`);
};

export const deletePermission = async (permissionId) => {
  return await axios.delete(`/permissions/${permissionId}`);
};

export const getAllRoles = async () => {
  return await axios.get("/roles?limit=50");
};

export const addUser = async (formData) => {
  return await axios.post("/users", formData);
};

export const addPermissions = async (formData) => {
  return await axios.post("/permissions", formData);
};

export const addRoles = async (formData) => {
  return await axios.post("/roles", formData);
};

export const updateUser = async (id, formData) => {
  return await axios.patch(`${apiUrl}/api/v1/updatePervillage/${id}`, formData );
};

export const updatePermissionById = async (permissionId, formData) => {
  return await axios.patch(`/permissions/${permissionId}`, formData);
};

export const updateRoleById = async (roleId, formData) => {
  return await axios.put(`/roles/${roleId}`, formData);
};

export const getUserById = async (id) => {
  return await axios.get(`/users/${id}`);
};

export const getRoleById = async (roleId) => {
  return await axios.get(`/roles/${roleId}`);
};

export const getPermissionById = async (permissionId) => {
  return await axios.get(`/permissions/${permissionId}`);
};

export const deleteUserById = async (id) => {
  return await axios.delete(`/users/${id}`);
};

export const getAllUsers = async (params) => {
  return await axios.get(`/users${params}`);
};

export const getAllUsersWithoutPagination = async (query) => {
  return await axios.get(`${apiUrl}/api/v1/getusers?name_email_role=${query}`);
};
