import { Check } from "@mui/icons-material";
import {
  Box,
  Checkbox,
  Grid,
  FormGroup,
  FormControlLabel,
} from "@mui/material";
import { Select, Skeleton, notification } from "antd";
import Aos from "aos";
import axios from "axios";
import React, { useCallback, useEffect, useState } from "react";
import {
  useCollapse as expand1,
  useCollapse as expandReference,
  useCollapse,
} from "react-collapsed";
import { ImBlocked } from "react-icons/im";
import { useSelector } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { Tab, TabList, Tabs } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { ReactComponent as Icon } from "../../../assests/image/Icon.svg";
import { ReactComponent as Share } from "../../../assests/image/share.svg";
import "../../../assests/styles/landing.scss";
import { apiUrl } from "../../../config";
import { copyToClipboard, currentDate } from "../../../helper";
import ShareModel from "../Share_model/Share_model";
import Slider from "../Slider";
import "./ArticlesBoard.css";
import Reference from "./Reference";
import QuestionMark from "../QuestionMark/QuestionMark";
import InactiveBackgroundBox from "./drawerBg.png";
import ActiveBackgroundBox from "./drawerBgRed.png";
import AdSense from "../../../adsence/Asence";

export const stdCategories = [
  "Overall",
  "Politics",
  "Business and Finance",
  "Entertainment",
  "Science and Technology",
  "Sports",
  "Crypto/Web3",
  "Gaming",
  "Law and Crime",
  "Lifestyle and Health",
  "Art and Fashion",
];

const trendsTimeframeOptions = [
  {
    value: "day",
    label: "1 Day",
  },
  {
    value: "week",
    label: "1 Week",
  },
  {
    value: "month",
    label: "1 Month",
  },
];

function ArticlesBoard({
  moreCount = 5,
  timeframe = "day",
  category = stdCategories[0],
  date = currentDate(),
  isShare = false,
}) {
  const [EnablePredictions, setEnablePredictions] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const isuser = useSelector((state) => state.UserAuth.isAuth);
  const [Days_text, setDays_text] = useState("Day");
  const [relevantNews, setRelevantNews] = useState(false);
  const [moreOption, setMoreOption] = useState(false);
  const [Density_error, setDensity_error] = useState(false);
  const [Predicted_categories, setPredicted_categories] = useState(category);
  const [showAccountMessage, setShowAccountMessage] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [value, setValue] = useState(5);
  const [Predicted_error, setPredicted_error] = useState(false);
  const [trendsTimeframe, setTrendsTimeframe] = useState(timeframe);
  const [categories, setCategories] = useState(stdCategories);
  const [toggleDiv, setToggleDiv] = useState(false);
  const [selectedTab, setSelectedTab] = useState("Day");
  const [selectedCategory, setSelectedCategory] = useState("politics");
  const [trendsData, setTrendsData] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();
  const currentRoute = location.pathname;

  const url = new URL(window.location.href);
  const origin = url.origin;

  const handleChange = (value) => {
    setTrendsTimeframe(value);
  };

  const [allArticals, setAllArticals] = useState([]);

  const fetchTrends = async () => {
    try {
      const response = await axios.get(`${apiUrl}/api/v1/trends`);
      setTrendsData(response.data.data);
    } catch (error) {
      console.error("Error fetching trends:", error);
    }
  };

  useEffect(() => {
    fetchTrends();
  }, []);

  const categoryData = {
    politics: [
      "Legislation (developed vs developing nations)",
      "Geopolitical Relations",
      "Elections",
      "Governance and Corruption",
      "Global Organizations",
      "Conflicts",
    ],
    businessAndFinance: [
      "Mergers and Acquisitions",
      "Earnings",
      "Corporate Strategy",
      "Market Positioning",
      "Commodity Prices",
      "FOREX",
      "Alternative Investments",
      "Interest Rates and Inflation",
      "Employment",
      "Consumer Confidence",
      "Trade",
      "Personal Finance",
    ],
    entertainment: [
      "Box Office Reports",
      "Video Streaming",
      "Award Shows",
      "Celebrity News",
      "Concert/Tours",
      "Music Streaming",
      "Books and Publishing",
      "Performing Arts",
    ],
    scienceAndTechnology: [
      "Breakthrough Discoveries",
      "Technological Advancements",
      "Research Publications",
      "Product Launches",
      "Cybersecurity Trends",
      "AI and Machine Learning",
      "Space",
      "Environment",
    ],
    sports: [
      "Soccer",
      "American Football",
      "Basketball",
      "Baseball",
      "Tennis",
      "Golf",
      "Track and Field",
      "Boxing and MMA",
      "Racing",
      "Sports Business",
    ],
    cryptoWeb3: [
      "Bitcoin",
      "Ethereum",
      "Regulations",
      "Innovations",
      "DeFi Projects and Protocols",
      "NFT's",
    ],
    gaming: [
      "Game News and Updates",
      "Game Reviews",
      "Indie Games",
      "Esports",
      "Industry Trends",
      "Streamers",
    ],
    lawAndCrime: [
      "Supreme Court Decisions",
      "Major Lawsuits",
      "Crime",
      "Law Enforcement",
      "Cybercrime",
      "Fraud",
    ],
    lifestyleAndHealth: [
      "Medical Research and Innovations",
      "Mental Health",
      "Fitness and Nutrition",
      "Personal Development",
      "Travel and Leisure",
      "Pharma",
      "Health Insurance",
    ],
    artAndFashion: [
      "Art Market",
      "Visual Arts",
      "Fashion Industry",
      "Cultural Events",
      "Design and Architecture",
    ],
  };

  const copyToCliboard = () => {
    copyToClipboard(
      `${origin}/share/${btoa(
        JSON.stringify({
          type: "articlesboard",
          date,
          timeframe,
          category: Predicted_categories,
        })
      )}`
    );
    notification.open({
      message: "Link Copied",
      placement: "top",
      className: "notification",
      closeIcon: <Check />,
      style: {
        width: "200px",
      },
    });
  };

  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const [activeCategory, setActiveCategory] = useState(0);

  const {
    getCollapseProps: getCollapseProps1,
    getToggleProps: getToggleProps1,
    isExpanded: isExpanded1,
  } = expandReference();

  const {
    getCollapseProps: getCollapseProps3,
    getToggleProps: getToggleProps3,
    isExpanded: isExpanded3,
  } = expand1();
  const {
    getCollapseProps: getCollapseProps5,
    getToggleProps: getToggleProps5,
    isExpanded: isExpanded5,
  } = expand1();

  useEffect(() => {
    Aos.init();
    const idx = stdCategories.indexOf(category);
    if (idx > -1) {
      setActiveCategory(idx);
    }
  }, []);

  const selectCategories = async (e) => {
    try {
      setActiveCategory(parseInt(e.target.value));
      setPredicted_categories(categories[parseInt(e.target.value)]);
      setMoreOption(true);
    } catch (error) {}
  };

  // const fetch_articalDetails = async (title, timeframe, datetime, category) => {
  //   const details_article = await axios.post(
  //     apiUrl + "/api/v1/articalDetails",
  //     {
  //       title: title,
  //       datetime: datetime,
  //       timeframe: timeframe,
  //       category: category === "Overall" ? "At a Glance" : category,
  //     }
  //   );
  //   return details_article.data.data[0];
  // };

  const fetch_articalsCategory = async (category, timeframe, datetime) => {
    const details_article = await axios.post(
      apiUrl + "/api/v1/articalCategories",
      {
        category: category === "Overall" ? "At a Glance" : category,
        timeframe: timeframe,
        datetime: datetime,
      }
    );
    return details_article.data.data[0].data;
  };

  const fetchingArticalsByCategory = async (category, timeframe, datetime) => {
    setspinner(true);
    try {
      const allArticalsData = await fetch_articalsCategory(
        category,
        timeframe,
        datetime
      );

      setAllArticals(allArticalsData);
    } catch (error) {
      console.error(error);
    } finally {
      setspinner(false);
    }
  };

  const fetchArticles = useCallback(() => {
    setAllArticals([]);
    fetchingArticalsByCategory(
      Predicted_categories,
      Days_text.toLowerCase(),
      date
    );
  }, [Predicted_categories, Days_text, date]);

  useEffect(() => {
    fetchArticles();
  }, [fetchArticles]);

  const tabOnClick = (text) => {
    if (isuser) {
      setDays_text(text);
    }
    setRelevantNews(true);
  };

  const TabContent = () => {
    switch (timeframe) {
      case "week":
        return "Week";
      case "month":
        return "Month";
      default:
        return "Today";
    }
  };

  const CheckBoxComponent = ({
    defaultChecked,
    className,
    onChange,
    label,
    caption,
  }) => (
    <div className="predictioncheckboxdiv">
      <input
        type="checkbox"
        defaultChecked={defaultChecked}
        className={className}
        onChange={onChange}
      />
      <span className="predictioncheckbox mx-2">
        {label}
        <QuestionMark caption={caption} />
      </span>
    </div>
  );

  const CheckBoxes = () => (
    <>
      <CheckBoxComponent
        defaultChecked={EnablePredictions}
        className="checkBox_Enable"
        onChange={(e) => setEnablePredictions(e.target.checked)}
        label="Enable Trends"
        caption="Developing Trends in each category of news"
      />
    </>
  );

  const DesktopNavList = () => (
    <ul className="tabssidebar list-category desktop_lag">
      {(isShare ? [category] : categories).map((category, index) => {
        const isActive = activeCategory === index;

        const listItemClass = isActive ? "list-item active" : "list-item";
        const handleClick = () => {
          setActiveCategory(index);
          if (isuser || index < moreCount - 1) {
            setMoreOption(!isuser && index >= moreCount - 1);
            setPredicted_categories(category);
          }
          window.gtag("event", "button_click", {
            event_category: "news_button",
            event_action: "Click",
            event_label: category,
          });
        };
        const style = {
          padding: "10px",
          paddingBottom: "0px",
          backgroundImage:
            !isuser &&
            (index > 4
              ? `url(${isActive ? ActiveBackgroundBox : InactiveBackgroundBox})`
              : ActiveBackgroundBox),
          backgroundSize: "cover",
          width: "280px",
          height: "72px",
          marginBottom: 0,
        };

        return (
          <li key={index} className={listItemClass} onClick={handleClick}>
            <p style={style}>{category}</p>
          </li>
        );
      })}
    </ul>
  );

  const ScrollToTop = () => window.scrollTo(0, 0);

  const AuthLink = ({ className }) => (
    <Link to="/signup" className={className} onClick={ScrollToTop}>
      <div
        className="d-flex justify-content-center align-items-center"
        data-aos="fade-down"
        data-aos-easing="linear"
        data-aos-duration="500"
      >
        <ImBlocked className="Auth_icon" />
        <p className="Auth_p">Create a FREE Account to access this feature!</p>
      </div>
    </Link>
  );

  const CategoryOptions = ({ categories, isUser, moreCount }) =>
    categories.map((categ, index) => (
      <option key={index} className="list-item option-color" value={index}>
        {isUser || index < moreCount - 1 ? categ : `🔒 ${categ}`}
      </option>
    ));

  const TabItems = ({ isShare, tabOnClick }) => {
    const handleTabClick = (tab) => {
      setSelectedTab(tab);
      tabOnClick(tab);
    };

    return (
      <div>
        {isShare ? (
          <Tab>
            <TabContent />
          </Tab>
        ) : (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              listStyleType: "none",
            }}
          >
            <Tab
              onClick={() => handleTabClick("Day")}
              className={selectedTab === "Day" ? "selected-tab" : "tab"}
            >
              Today
            </Tab>
            <Tab
              onClick={() => handleTabClick("Week")}
              className={selectedTab === "Week" ? "selected-tab" : "tab"}
            >
              Week
            </Tab>
            <Tab
              onClick={() => handleTabClick("Month")}
              className={selectedTab === "Month" ? "selected-tab" : "tab"}
            >
              Month
            </Tab>
            <QuestionMark
              caption={"View the news from a different timeframe perspective"}
            />
          </div>
        )}
      </div>
    );
  };

  const ContentLeftSider = ({
    isUser,
    moreOption,
    isShare,
    categories,
    selectCategories,
    moreCount,
    relevantNews,
    tabOnClick,
  }) => (
    <div className="content-left-sider" style={{ marginRight: "50px" }}>
      {!isUser && moreOption && <AuthLink className="link_dec responsei_bar" />}
      <select
        className="tabssidebar list-category responsei_bar mb-3"
        onChange={selectCategories}
        value={activeCategory}
      >
        {!isShare && (
          <CategoryOptions
            categories={categories}
            isUser={isUser}
            moreCount={moreCount}
          />
        )}
      </select>
      {!isUser && relevantNews && <AuthLink className="link_dec" />}
      <div
        className={
          isUser || !relevantNews ? "select-date-type" : "select-date-type1"
        }
      >
        <Tabs>
          <TabList
            style={
              isShare
                ? undefined
                : {
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "center",
                  }
            }
          >
            <TabItems isShare={isShare} tabOnClick={tabOnClick} />
          </TabList>
        </Tabs>
      </div>
      <CheckBoxes />
      <DesktopNavList />
      {moreOption && <AuthLink className="link_dec me-4 desktop_lag" />}
    </div>
  );

  const RedirectToSignUp = () => {
    const scrollToTop = () => window.scrollTo(0, 0);

    return (
      <Link to="/signup" className="link_dec" onClick={scrollToTop}>
        <div
          className="d-flex justify-content-center  align-items-center mt-3"
          data-aos="fade-down"
          data-aos-easing="linear"
          data-aos-duration="500"
        >
          <div className="d-flex justify-content-center  align-items-center Show_Err0r_ISAuth ">
            <ImBlocked className="Auth_icon" />
            <p className="Auth_p">
              Create a FREE Account to access this feature!
            </p>
          </div>
        </div>
      </Link>
    );
  };

  const ArticleContainer = ({ allArticles, spinner }) => {
    const [isArticleExpanded, setIsArticleExpanded] = useState(false);
    const {
      getCollapseProps: getArticleCollapseProps,
      getToggleProps: getArticleToggleProps,
    } = useCollapse({ isExpanded: isArticleExpanded });

    const displayArticles = () => {
      if (allArticles.length > 0) {
        return (
          <>
            {allArticles.slice(0, 2).map((article, index) => (
              <TopicDetail
                key={index}
                article={article}
                allowDeepDiveWithoutAuth={true}
              />
            ))}
            <section
              {...getArticleCollapseProps()}
              style={{
                width: "-webkit-fill-available",
              }}
            >
              {allArticles.slice(2, value).map((article, index) => (
                <TopicDetail key={index} article={article} />
              ))}
            </section>
          </>
        );
      } else {
        return <Skeleton />;
      }
    };

    return (
      <div className="card card-content margin-b-13 articlesContainer">
        <div
          className="spanhead"
          style={{
            display: "block",
            textAlign: "right",
          }}
        >
          <div onClick={() => copyToCliboard()} style={{ cursor: "pointer" }}>
            <Share />
          </div>
        </div>
        {spinner ? (
          <Skeleton />
        ) : (
          <div className="containerFLEX">{displayArticles()}</div>
        )}
        {!isuser && (
          <span
            {...getArticleToggleProps({
              onClick: () =>
                setIsArticleExpanded((prevExpanded) => !prevExpanded),
            })}
            className="expand"
            style={{ textAlign: "center" }}
          >
            {allArticles.length > 0
              ? isArticleExpanded
                ? "Collapse-"
                : "Expand+"
              : "Loading..."}
          </span>
        )}
      </div>
    );
  };

  function removeMatchingSecondary(obj) {
    if (!obj || !obj.primary || !obj.secondary) {
      console.error("Invalid object structure");
      return obj;
    }

    const primaryTitle = obj.primary.title;
    obj.secondary = obj.secondary.filter(
      (secondaryItem) => secondaryItem.title !== primaryTitle
    );

    return obj.secondary;
  }

  const TopicDetail = ({ article, allowDeepDiveWithoutAuth }) => {
    const deepDive = (defaultActive = -1) => {
      const redirectToDeepDive = () => {
        navigate("/Deep_Dive", {
          state: {
            artical: article,
            timeframe: Days_text.toLowerCase(),
            category: Predicted_categories,
            datetime: date,
            back: currentRoute,
            defaultActive,
          },
        });
        window.scrollTo(0, 0);
      };

      if (!isuser && !allowDeepDiveWithoutAuth) {
        setShowAccountMessage(true);
      } else {
        redirectToDeepDive();
      }
    };
    const uniqueSecondaryArticles = removeMatchingSecondary(article);

    return (
      <>
        <div className="titleContainer">
          <p className="titleArtical">{article.title}</p>
          <div
            className="deep_text_langingg"
            style={{
              marginLeft: 0,
              marginTop: 0,
              marginBottom: "0.1rem",
              minWidth: "120px",
              textAlign: "start",
            }}
            onClick={() => deepDive()}
          >
            Dive Deeper &gt;&gt;&gt;
          </div>
          {!isuser && showAccountMessage && !allowDeepDiveWithoutAuth && (
            <Link
              to="/signup"
              style={{ textDecoration: "none", marginLeft: "2rem" }}
              onClick={() => window.scrollTo(0, 0)}
            >
              <div className="errorr d-flex justify-content-center  align-items-center ">
                <ImBlocked className="Auth_icon1" />
                <p className="Auth_p1 mt-2 ">
                  Create a FREE Account to <br /> access this feature!
                </p>
              </div>
            </Link>
          )}
        </div>
        <ul style={{ maxWidth: "100%" }}>
          {article.primary && (
            <li>
              <p className="articalText">
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a className="underLineA" onClick={() => deepDive()}>
                  {article.primary.title}
                </a>{" "}
                {article.primary.summary}
              </p>
            </li>
          )}
          {uniqueSecondaryArticles &&
            uniqueSecondaryArticles.length > 0 &&
            uniqueSecondaryArticles
              ?.filter((x) => x.summary) // filter out articles without summary
              .map(
                (uniqueSecondaryArticle, index) =>
                  uniqueSecondaryArticle && (
                    <li key={index}>
                      <p className="articalText">
                        {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                        <a className="underLineA" onClick={() => deepDive()}>
                          {uniqueSecondaryArticle.title}
                        </a>{" "}
                        {uniqueSecondaryArticle.summary}
                      </p>
                    </li>
                  )
              )}
        </ul>
      </>
    );
  };

  const CardStyles = {
    backgroundColor: isuser || !Density_error ? "" : "#FFE6E6",
    border: isuser || !Density_error ? "" : "1px solid rgba(232, 3, 112, 0.8)",
  };

  const RenderPredictedDevs = ({ trendsData, timeframe, category }) => {
    console.log(timeframe, trendsData, category, !trendsData);
    if (!trendsData) return <Skeleton />;

    const timeframeMap = {
      day: "day",
      week: "week",
      month: "month",
    };

    const trendsTimeframe = timeframeMap[timeframe] || "day";
    const trendEntry = trendsData.find((t) => t.timeframe === trendsTimeframe);

    if (!trendEntry) return null;

    const categoryMap = {
      Overall: "Overall",
      Politics: "Politics",
      "Business and Finance": "Biz and Finance",
      Entertainment: "Entertainment",
      "Science and Technology": "Science and Tech",
      Sports: "Sports",
      "Crypto/Web3": "Crypto/web3",
      Gaming: "Gaming",
      "Law and Crime": "Law and Crime",
      "Lifestyle and Health": "Lifestyle and Health",
      "Art and Fashion": "Art and Fashion",
    };

    const mappedCategory = categoryMap[category] || category;
    const trendText = trendEntry.data[mappedCategory];

    return !trendText ? (
      <Skeleton />
    ) : (
      <>
        <h3 className="headerbox text-left pb-3">{`Trends for the ${timeframe}`}</h3>
        <div className="pb-4">
          <span dangerouslySetInnerHTML={{ __html: trendText }} />
        </div>
      </>
    );
  };

  const selectStyle = {
    width: "10rem",
    borderRadius: "5px",
    background: "transparent",
  };

  const disclaimerStyle = {
    color: "#00B2FF",
    textDecoration: "underline",
    cursor: "pointer",
  };

  const containerStyle = {
    textAlign: "center",
    flexDirection: "column-reverse",
  };

  const PredictionsHeader = () => (
    <div className="d-flex align-items-center" style={containerStyle}>
      <div className="mt-2">
        <Select
          defaultValue={trendsTimeframe}
          style={selectStyle}
          onChange={handleChange}
          options={trendsTimeframeOptions}
        />
      </div>
      <div className="Predictions mt-5">
        <span>
          Trends in:
          {(isShare ? [category] : categories).map((category, index) => {
            const isActive = activeCategory === index;
            return (
              <span style={{ color: "#878787" }}> {isActive && category} </span>
            );
          })}
          <QuestionMark
            caption={
              'Similarities and insights gathered from the articles we analyze. View a more specialized approach by choosing from of our many "profiles"'
            }
          />
        </span>
        <br />
        <span className="disclaimer">
          *
          <span
            onClick={() => navigate("/Legal_Docs?type=3")}
            style={disclaimerStyle}
          >
            Disclaimer
          </span>
          * &nbsp;For educational purpose only
        </span>
      </div>
    </div>
  );

  const formatCategoryName = (name) => {
    return name
      ?.replace(/([A-Z])/g, " $1") // Insert space before capital letters
      ?.replace(/^./, (str) => str.toUpperCase()) // Capitalize the first letter
      .trim();
  };
  const handleCategoryChange = (event, category) => {
    setSelectedCategory(category);
  };

  return (
    <div className="content-body ">
      <ShareModel
        isModalOpen={isModalOpen}
        handleOk={handleOk}
        handleCancel={handleCancel}
        shareLink={`${origin}/share/${btoa(
          JSON.stringify({ date, timeframe, category: Predicted_categories })
        )}`}
      />

      <ContentLeftSider
        isUser={isuser}
        moreOption={moreOption}
        isShare={isShare}
        categories={categories}
        selectCategories={selectCategories}
        moreCount={moreCount}
        relevantNews={relevantNews}
        tabOnClick={tabOnClick}
      />

      <div className="content-right-sider" style={{ textAlign: "left" }}>
        <AdSense
          client={"ca-pub-5336140276709901"}
          slot={"1524901247"}
          format={"auto"}
          responsive={"true"}
        />

        <Grid container spacing={1}>
          <Grid item xs={12} md={7}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <div className="topheadcard impactfuleNews-head">
                Most Influential News of the {Days_text}:
              </div>
              <div style={{ marginBottom: "5px" }}>
                <QuestionMark
                  caption={
                    '"Influential" dependent on the criteria of: Political significance, Economic consequesnces, Social consequences, Public attention and interest'
                  }
                />
              </div>
            </div>
          </Grid>
        </Grid>

        <div
          className="arrow-box"
          style={!toggleDiv ? { display: "none" } : { display: "block" }}
        >
          <Box
            style={{
              minWidth: "700px",
              maxHeight: "200px",
              padding: "5px",
              overflow: "scroll",
            }}
          >
            <Grid container spacing={0}>
              <Grid item xs={6}>
                <p className="titleArtical" style={{ marginBottom: "0px" }}>
                  Standard Benchmarks//
                </p>
                <FormGroup>
                  {Object.keys(categoryData).map((category, index) => (
                    <FormControlLabel
                      key={index}
                      style={{ marginBottom: "-10px" }}
                      control={
                        <Checkbox
                          checked={selectedCategory === category}
                          onChange={(event) =>
                            handleCategoryChange(event, category)
                          }
                        />
                      }
                      label={formatCategoryName(category)}
                    />
                  ))}
                </FormGroup>
              </Grid>
              <Grid item xs={6}>
                <p className="titleArtical" style={{ marginBottom: "0px" }}>
                  Category Specific//
                </p>
                <FormGroup>
                  {categoryData[selectedCategory].map((label, index) => (
                    <FormControlLabel
                      key={index}
                      style={{ marginBottom: "-10px" }}
                      control={<Checkbox defaultChecked={index === 0} />}
                      label={label}
                    />
                  ))}
                </FormGroup>
              </Grid>
            </Grid>
          </Box>
        </div>

        <ArticleContainer allArticles={allArticals} spinner={spinner} />

        <div
          className="summaryRef"
          onClick={() => {
            setDensity_error(true);
          }}
        >
          <div className="card_and_Auth">
            <div
              className="card card-setting-denisty margin-r-13"
              style={CardStyles}
            >
              <div className="card-setting-title">
                <div>
                  Set Dashboard Density{" "}
                  <QuestionMark
                    caption={
                      "view from 2 to 20 of the most influential in the main user dashboard"
                    }
                  />
                </div>
                <Slider
                  setDensity_error={setDensity_error}
                  value={value}
                  setValue={setValue}
                />
              </div>
              <div className="card-setting-body"></div>
            </div>

            {!isuser && Density_error && <AuthLink className="link_dec" />}
          </div>

          <div className="card card-setting-references">
            <div className="card-setting-title">References</div>
            <div className="card-setting-body">
              <div
                className="news-list"
                style={{
                  // height: isExpanded1 ? "140px" : "140px",
                  height: "140px",
                  width: "300px",
                  margin: "0 auto",
                  overflow: "scroll",
                }}
              >
                {allArticals.map((artical, index) => {
                  if (
                    index > 1 ||
                    !artical.title ||
                    !artical.secondary ||
                    artical.secondary.length === 0
                  )
                    return <></>;
                  return (
                    <div
                      key={artical._id}
                      className="news-list-item"
                      style={{
                        display: "block",
                        height: "auto",
                      }}
                    >
                      <Reference
                        title={artical.title[0]}
                        links={[artical.primary, ...artical.secondary].map(
                          (article) => article?.link
                        )}
                      />
                    </div>
                  );
                })}
                <section {...getCollapseProps1()}>
                  {allArticals.map((artical, index) => {
                    if (
                      index <= 1 ||
                      !artical.title ||
                      !artical.secondary ||
                      artical.secondary.length === 0
                    )
                      return <></>;
                    return (
                      <div
                        key={artical._id}
                        className="news-list-item"
                        style={{
                          display: "block",
                          height: "120px",
                        }}
                      >
                        <Reference
                          title={artical.title[0]}
                          links={[artical.primary, ...artical.secondary].map(
                            (article) => article?.link
                          )}
                        />
                      </div>
                    );
                  })}
                </section>
              </div>
              <div
                {...getToggleProps1()}
                className=" reference-show-all"
                style={{
                  paddingLeft: "50%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                {isExpanded1 ? (
                  <>
                    <>
                      <Icon
                        className="upicon"
                        style={{ transform: "rotate(180deg)" }}
                      />
                      <Link className="link">Show less</Link>
                    </>
                  </>
                ) : (
                  <>
                    <Icon className="downicon" />
                    <Link className="link">Show All</Link>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
        {EnablePredictions && (
          <>
            <PredictionsHeader />
            <div className="Predictions">
              <div className="boxes1">
                <div
                  className="box1"
                  style={{
                    backgroundColor: isuser ? "" : "#FFE6E6",
                    border: isuser ? "" : "1px solid rgba(232, 3, 112, 0.8)",
                  }}
                >
                  <RenderPredictedDevs
                    trendsData={trendsData}
                    timeframe={trendsTimeframe}
                    category={Predicted_categories}
                  />
                </div>
              </div>
            </div>

            {!isuser && Predicted_error && <AuthLink className="link_dec" />}
          </>
        )}
      </div>
    </div>
  );
}

export default ArticlesBoard;
